.input-dropdown {
  position: relative;

  &-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;

    &.is-active {
      display: block;
    }
  }
}