.is-paddingless {
  padding: 0 !important;
}

.is-marginless {
  margin: 0 !important;
}

.is-marginless-top {
  margin-top: 0 !important;
}

.is-borderless {
  border-width: 0px !important;
}

.has-layout {
  padding: calc(#{$padding-layout} / 2) 0;
}

@each $size in $sizes {
  $i: index($sizes, $size);
  .has-text-size-#{$i} {
    font-size: $size;
  }
}

.is-text-gray-light {
  color: $grey-light;
}