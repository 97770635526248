$icon-width: 70px;

.category-filter {
  width: 100%;

  .filter-ref-wrapper {
    display: inline-block;

    .filter-item-container {
      display: flex;
      flex-wrap: nowrap;

      .filter-item {
        min-width: $icon-width;
        padding: 12px 7px;

        &:nth-child(1) {
          padding-left: 0px;
        }

        &:nth-last-child(1) {
          padding-right: 0px;
        }

        &-icon {
          width: $icon-width;
          height: $icon-width;
          overflow: hidden;
          margin-bottom: 5px;
          padding: 8px;
          border: 1px solid transparent;
        }

        &-title {
          font-size: $size-7;
          text-align: center;
          text-transform: capitalize;
        }

        &.is-active {
          .filter-item-icon {}
        }
      }
    }
  }

}