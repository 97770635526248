/**
 * ----------------------------------------------------------------------------
 * DB OZone X
 * ----------------------------------------------------------------------------
 * format: ttf
 * Weight scale: regular    400
 *               bold       700
 */
@include font-face("DBOZoneX", "../../assets/fonts/DBOzoneX/DBOzoneX", 400, normal, eot otf woff ttf);
@include font-face("DBOZoneX", "../../assets/fonts/DBOzoneX/DBOzoneXBd", 700, normal, ttf);
@include font-face("DBOZoneX", "../../assets/fonts/DBOzoneX/DBOzoneXMed", 500, normal, ttf);
@include font-face("DBOZoneX", "../../assets/fonts/DBOzoneX/DBOzoneXLi", 200, normal, ttf);
@include font-face("DBOZoneX", "../../assets/fonts/DBOzoneX/DBOzoneXUltraLi", 100, normal, ttf);
