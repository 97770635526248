$collapse-content-min-height: 90px;

.collapse {
  width: 100%;
  border-bottom-width: $border-width;
  border-bottom-style: $border-style;

  &-header {
    display: flex;
    padding: 5px $padding-layout;
    padding-top: 18px;
    justify-content: center;
    align-items: flex-end;
    border-bottom-width: $border-width;
    border-bottom-style: $border-style;
  }

  &-title-container {
    flex: 1;
  }

  &-title {
    font-weight: 500;
  }

  &-arrow-container {
    text-align: right;
    z-index: -1;

    .collapse-arrow {
      width: 1em;
      height: 1em;
      transition: transform cubic-bezier(0, 1, .29, 1.39) $transition-duration;
    }
  }

  &-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px calc(#{$padding-layout} + 13px);
  }

  &-content {
    transition: height $transition-timing $transition-duration;
  }

  &.is-expanded {
    .collapse-arrow {
      transform: rotateZ(-180deg);
    }

    .collapse-cover {
      opacity: 0;
      display: none;
      position: absolute;
      transition: opacity $transition-duration, display $transition-duration;
    }
  }

  &.is-collapsed {
    .collapse-cover {
      opacity: 1;
      transition: opacity $transition-duration;
    }
  }
}
