$displays: block, inline;
$aligns: inherit, left, center, right, justify;

.typography {
  @each $display in $displays {
    &.is-display-#{$display} {
      display: $display;
    }
  }

  @each $align in $aligns {
    &.is-align-#{$align} {
      text-align: $align;
    }
  }

  &.is-title {
    @include text(bold, capitalize);
  }

  &.is-subtitle {
    @include text(500, capitalize);
  }
}
