$input-border-radius: $border-radius;
$input-border-style: $border-style;
$input-border-width: $border-width;

$input-height: 2.5em;
$input-width: 100%;

$label-weight: 500;

.field {
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  .label:not(:last-child) {
    margin-bottom: .5em;
  }

  .label {
    display: block;
    font-size: $size-6;
    font-weight: $label-weight;

    @each $name, $value in $element-sizes {
      &.is-#{$name} {
        font-size: $value;
      }
    }
  }

  .control {
    clear: both;
    position: relative;
    text-align: left;

    .input {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      height: $input-height;
      max-width: 100%;
      width: $input-width;
      border-width: $input-border-width;
      border-radius: $input-border-radius;
      border-style: $input-border-style;
      padding: 8px 15px;
      font-size: $size-6;

      @each $name, $value in $element-sizes {
        &.is-#{$name} {
          font-size: $value;
        }
      }

      &.is-borderless {
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    .input-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;  

      &.is-left {
        left: 0;
      }

      &.is-right {
        right: 0;
      }
    }

    &.has-icon-left {
      .input {
        padding-left: $input-height;
      }
    }

    &.has-icon-right {
      .input {
        padding-right: $input-height;
      }
    }

    &.has-icon-left,
    &.has-icon-right {
      .input-icon {
        position: absolute;
        top: 0;
        height: $input-height;
        width: $input-height;
        pointer-events: none;
        z-index: 4;

        &.is-clickable {
          pointer-events: auto;
        }
      }

      .input {
        @each $name, $value in $element-sizes {
          &.is-#{$name} ~ .input-icon {
            font-size: $value;

            .icon {
              font-size: calc(#{$value} + .5rem);
            }
          }
        }
      }
    }
  }
}