.branch-filter {
  width: 100%;

  &-title {
    margin-bottom: $padding-layout;
    font-size: $size-6;
  }

  .filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;

    .filter-item {
      display: flex;
      flex-direction: column;

      &.is-disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      &:nth-child(1) {
        margin-left: 0;
        padding-left: 0px;
      }

      &:nth-last-child(1) {
        margin-right: 0;
        padding-right: 0px;
      }

      &-img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        // box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;

        img {
          object-fit: cover;
        }
      }

      &-title {
        text-align: center;
        font-size: $size-4;
      }

      &.is-active {
        .filter-item-img {
          outline-width: 2px;
          outline-style: solid;
        }
      }
    }
  }
}
