$list-item-height: 32px;

.list-item {
  position: relative;
  height: $list-item-height;
  display: flex;
  align-items: center;
  &-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &.is-left {
      left: 0;
    }

    &.is-right {
      right: 0;
    }
  }

  &.has-icon-left {
    .list-item-content {
      padding-left: $list-item-height;
    }
  }

  &.has-icon-right {
    .list-item-content {
      padding-right: $list-item-height;
    }
  }

  &.has-icon-left,
  &.has-icon-right {
    &-icon {
      position: absolute;
      top: 0;
      height: $list-item-height;
      width: $list-item-height;
      pointer-events: none;
      z-index: 4;

      &.is-clickable {
        pointer-events: auto;
      }
    }
  }

  &-content {
    width: 100%;
  }
}