  .shop-list-item {
    display: flex;
    padding: .5rem;
    height: 100px;
    align-items: center;
    outline: none;

    .prefix, .suffix {
      flex: 0 0 20px;
      height: 100%;
      .tag-index {
        width: 20px;
        height: 20px;
        border-radius: $circle-border-radius;
        margin-top: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
          font-size: $size-6;
          font-weight: 500;

        }
      }
    }

    .avatar {
      display: flex;
      justify-content: center;
      padding: .5rem;
      flex: none;

      .img-avatar {
        width: 70px;
        height: 70px;
        object-fit: scale-down;
      }

    }

    .title-texts {
      flex: 2;

      .title > span {
        font-size: $size-5;
      }

    }

    .detail-texts {
      flex: 1;
      justify-content: flex-end;
      margin-right: .5rem;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .floor {
        float: right;
        font-weight: 900;

        .floor-index {
          display: inline;
          font-size: $size-5;
          margin-left: .2rem;;
        }

        .button {
          min-width: 40px;
          height: 40px;
          margin-left: 5px;
          padding: 10px;
        }
      }

    }

    .subtitle {
      font-size: $size-6;
    }

    .branch {
      float: right;
      font-size: $size-6;
    }
  }
