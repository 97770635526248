$ad-hoc-branch-filter-height: 90px;

.search-result-screen {
  .ad-hoc-collapse-content-container {
    display: flex;
    align-items: center;
    min-height: $ad-hoc-branch-filter-height;
    padding: 0 $padding-layout;
  }

  .list-header {
    padding: 5px $padding-layout;
    margin-top: 20px;
    font-weight: 500;
  }
}
