$button-border-radius: $border-radius;
$button-border-radius-rounded: $circle-border-radius;

$button-padding-vertical: calc(0.75em - #{$border-width}) !default;
$button-padding-horizontal: 1em !default;

$button-min-width: 123px;

$button-icon-margin: calc(#{$padding-layout} / 2);

.button {
  padding: $button-padding-vertical $button-padding-horizontal;
  border-radius: $button-border-radius;
  border-style: $border-style;
  border-width: $border-width;
  outline: $outline;
  white-space: nowrap;
  text-align: center;
  font-size: $size-6;
  min-width: $button-min-width;

  &.is-rounded {
    border-radius: $button-border-radius-rounded;
  }

  .icon-left {
    margin-right: $button-icon-margin;
  }

  .icon-right {
    margin-left: $button-icon-margin;
  }

  @each $name, $value in $element-sizes {
    &.is-#{$name} {
      font-size: $value;
    }
  }
}