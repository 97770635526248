/**
 * ----------------------------------------------------------------------------
 * Font Face
 * ----------------------------------------------------------------------------
 * see more information https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
 */
 @mixin font-face ($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    src: $src;
    font-weight: $weight;
    font-style: $style;
  }
}

/**
 * ----------------------------------------------------------------------------
 * Construct text
 * ----------------------------------------------------------------------------
 * see more information https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
 */
@mixin text($weight, $transform) {
	@if $weight {
		font-weight: $weight;
	}

	@if $transform {
		text-transform: $transform;
	}
}