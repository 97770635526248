.input-banner {
  position: relative;
  margin-bottom: calc(20px + .5em);
  
  .overlap {
    position: absolute;
    width: 100%;
    bottom: 0;
    transform: translate(0px, 20px);
    padding: 0 $padding-layout;
  }
}
