.section {
  display: inline-block;
  width: 100%;
  margin: $padding-layout 0;
  padding: 0 $padding-layout;

  &.is-centered-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}