.search-screen {
  height: 100%;
  .graphic-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      max-width: 186px;
      width: 100%;
      height: auto;
    }
  }
}