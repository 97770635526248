.shop-item {
  padding: 15px;
  color: $grey;

  .title {
    padding-bottom: 5px;
    font-size: $size-6;
  }

  .category {
    font-size: $size-7;
  }
}