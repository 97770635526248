h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
b,
i,
label,
button,
strong {
  font-family: 'DBOZoneX';
  font-weight: normal;
  font-style: normal;
}
