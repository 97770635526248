/**
 * Screen width
 */
 $lg-screen:  1200px; /** large device (desktop) */
 $md-screen:  992px; /** medium device (desktop) */
 $sm-screen:   768px; /** small device (tablet) */
 $ip-5:       320px; /** iPhone 5/SE */
 $ip-6-x:     375px; /** iPhone 6/6S/X */
 $ip-plus:    414px; /** iPhone XS MAX/XR/end with Plus */
 
 /**
  * Border
  */
 $border-width:           1px;
 $active-border-width:    2px;
 $border-radius:          4px;
 $circle-border-radius:   999em;
 $border-style:           solid;
 $selected-border-width:  5px;
 
 $outline: none;
 
 /**
  * font-size
  * see more (https://ivomynttinen.com/blog/ios-design-guidelines)
  */
 $size-1:   3rem;
 $size-2:   2.5rem;
 $size-3:   2rem;
 $size-4:   1.5rem;
 $size-5:   1.25rem;
 $size-6:   1rem;
 $size-7:   .75rem;
 $size-8:   .6rem;
 $size-9:   .5rem;

 $sizes: ($size-1, $size-2, $size-3, $size-4, $size-5, $size-6, $size-7, $size-8, $size-9);
 $element-sizes: (small: $size-7, normal: $size-6, medium: $size-5, large: $size-4);
 
  /**
   * letter-spacing
   */
 $title-letter-spacing: 0.075rem;
 
  /**
   * layout
   */
 $padding-layout: 15px;
 
 /**
  * shadow
  */
 $box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);
 $hover-box-shadow: 0 0 5px hsla(0, 0%, 0%, .3);
 
 /*
  *
  */
 $larger-header-height: 447px;
 
 /*
  *
  */
 $disabled-opacity: 0.3;
 
 /*
  * Transition
  */
 $transition-duration: .3s;
 $transition-timing: ease-in-out;
 
 /*
  * Colors
  */
 $white: hsl(0, 0%, 100%);
 $white-ter: hsl(0, 0%, 96%);
 $white-bis: hsl(0, 0%, 98%);
 $grey-lighter: hsl(0, 0%, 86%);
 $grey-light: hsl(0, 0%, 71%);
 $grey: hsl(0, 0%, 48%);
 $black: hsl(0, 0%, 4%);
 $red: hsl(0, 81%, 56%);
 $orange: hsl(31, 92%, 54%);
 $blue-grey: hsl(198, 20%, 36%);
 $blue: hsl(206, 100%, 50%);
 $pink: hsl(324, 100%, 46%);