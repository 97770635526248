.fullscreen-loading {
  height: 100vh;
  
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}